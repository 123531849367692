import React, { useContext, useEffect } from "react"
import styled from "styled-components"

// Types
import { IPage, ISlice, IContactBlocks } from "../types/"
import { IBlockTheme } from "../types/prismic/ContactBlocks"

// Styles
import colors from "../styles/colors"

// Utils
import PageContext from "../utils/context/PageContext"

// Components
import { renderSlice } from "../components/helpers/slice"
import SEO from "../components/layout/SEO"
import PageHeader from "../components/layout/PageHeader"
import ContactBlocks from "../components/ContactBlocks"

// Config
import { config as defaultPageTransitionsConfig } from "../components/pageTransitions/config"

interface IProps {
  data: IPage
  slices: ISlice[]
  contactBlocksData: IContactBlocks
}

// Setup
const pageTransitionsConfig = {
  ...defaultPageTransitionsConfig,
  particle: {
    ...defaultPageTransitionsConfig.particle,
    fillStyle: colors.white,
  },
}

const TeamPage = ({ data, slices, contactBlocksData }: IProps) => {
  const page = useContext(PageContext)

  const contactBlocksOptions = {
    ...contactBlocksData,
    detailsBlockTheme: "accent" as IBlockTheme,
    contactBlockTheme: "light" as IBlockTheme,
  }

  useEffect(() => {
    page.updateColor(colors.black)
    page.updateTransition(pageTransitionsConfig)
  }, [])

  return (
    <Wrapper>
      <SEO title={data.metaTitle} description={data.metaDescription} />

      {data && (
        <PageHeader
          pageTitle={data.pageTitle}
          introTitle={data.introTitle}
          introCopy={data.introCopy}
        />
      )}

      <Content>
        {slices &&
          slices.length &&
          slices.map((slice: ISlice) => (
            <Section key={slice.id}>{renderSlice(slice)}</Section>
          ))}
      </Content>

      <ContactBlocks {...contactBlocksOptions} />
    </Wrapper>
  )
}

// Utility styles
const Section = styled.section`
  margin-bottom: var(--base-column-size);

  &:last-of-type {
    margin-bottom: 0;
  }
`

// Main styles
const Wrapper = styled.div`
  color: ${colors.white};
`

const Content = styled.div`
  margin-bottom: calc(var(--base-column-size) * 2);
`

export default TeamPage
