import React from "react"
import { graphql } from "gatsby"

// Types
import { ISlice } from "../types/"

// Utils
import {
  formatPrismicData,
  formatContactBlocks,
} from "../utils/helpers/prismicHelpers"

// Components
import TeamPage from "../pageViews/teamPageView"

const TeamPageContainer = ({ data }: any) => {
  if (!data.teamPage) {
    return null
  }

  let slices
  const rawData = data.teamPage.data
  const rawFooterBlocksData = data.vacancyFooterBlocks.data

  const formattedData = {
    metaTitle: rawData.metaTitle,
    metaDescription: rawData.metaDescription,
    pageTitle: rawData.page_title,
    introTitle: rawData.intro_title,
    introCopy: rawData.intro_copy,
  }

  const formattedContactBlocksData = formatContactBlocks(rawFooterBlocksData)

  if (rawData.slices) {
    slices = rawData.slices
      .filter((slice: any) => slice.type && slice)
      .map((slice: any) => formatPrismicData(slice))
      .filter((slice: ISlice) => slice !== null)
  }

  return (
    <TeamPage
      data={formattedData}
      slices={slices}
      contactBlocksData={formattedContactBlocksData}
    />
  )
}

export const query = graphql`
  query GetTeamPage {
    teamPage: prismicTeamPage {
      data {
        metaTitle: meta_title
        metaDescription: meta_description
        page_title
        intro_title
        intro_copy {
          html
        }
        slices: body {
          type: slice_type
          id
          primary {
            tribe_name {
              document {
                data {
                  name
                }
              }
            }
          }
          tribeItems: items {
            team_member {
              document {
                data {
                  name
                  job_title
                  photo {
                    thumb {
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    vacancyFooterBlocks: prismicVacancyFooterBlocks {
      data {
        block_title_1 {
          raw {
            text
          }
        }
        block_copy_1 {
          html
        }
        block_link_name_1
        block_title_2 {
          raw {
            text
          }
        }
        block_copy_2 {
          html
        }
        block_link_name_2
      }
    }
  }
`

export default TeamPageContainer
